<template>
  <div class="horiz-box">
    <h1>Catalog</h1>
  </div>
  <div class="catalog">
    <div v-for="piece in extant" class="piece" @click="focusPiece = piece">
      <div class="inner">
        <div class="video">
          <video autoplay loop muted playsinline preload="metadata">
              <!-- :poster="getAssetUrl(thumbnailNameFromVideo(video))" -->
            <source :src="getAssetUrl('catalog/' + piece.video)" type="video/mp4">
          </video>
        </div>
        <div class="title">{{ piece.title }}</div>
      </div>
    </div>
  </div>
  <div class="horiz-box">
    <h1>Unmade Designs</h1>
  </div>
  <div class="catalog">
    <div v-for="piece in designs" class="piece" @click="focusPiece = piece">
      <div class="inner">
        <div class="video">
          <video autoplay loop muted playsinline preload="metadata">
              <!-- :poster="getAssetUrl(thumbnailNameFromVideo(video))" -->
            <source :src="getAssetUrl('catalog/' + piece.video)" type="video/mp4">
          </video>
        </div>
        <div class="title">{{ piece.title }}</div>
      </div>
    </div>
  </div>

  <div v-if="focusPiece" id="focus-piece" @click="focusPiece = null">
    <div class="inner">
      <div class="video">
        <video autoplay loop muted playsinline preload="metadata">
            <!-- :poster="getAssetUrl(thumbnailNameFromVideo(video))" -->
          <source :src="getAssetUrl('catalog/' + focusPiece.video)" type="video/mp4">
        </video>
      </div>
      <div class="title">{{ focusPiece.title }}</div>
      <div class="description">{{ focusPiece.description }}</div>
    </div>
  </div>

  <div class="horiz-box">
    <h1>Want more?</h1>
  </div>
  <div class="horiz-box"></div>
  <Signup/>
</template>

<script>
import Signup from "@/components/Signup"
import FancyButton from "@/components/FancyButton"
import Icon from "@/components/Icon"

export default {
  name: 'Home',
  components: { Signup, FancyButton, Icon },
  data() {
    return  {
      focusPiece: null,
      extant: [
        {
          video: "borromean.mp4",
          title: "Borromean Octant",
          description: `While working on another project I realized I wanted to make a "Möbius topology"
          (i.e. something where the backside becomes the front), but had to change the code a fair bit to make that work.
          While testing that functionality I had the idea for this piece (which very much has Möbius topology).
          It also happens to test a bunch of other features I'd been working on
          (mostly related to using pure 3D printing and dropping laser cutting) and kinda make a mess of things
          while doing so, so I was looking for a smaller test piece before moving back to the larger project.`
        },
        {
          video: "backlight.mp4",
          title: "Lion Backlight",
          description: `I initially designed a whole frame, but the laser cutting ended up too messed up to save,
          so I offered the client a couple different options and they chose this simple backlit rectangle,
          which I think turned out rather well.`
        },
        {
          video: "made.mp4",
          title: "The MADE sign",
          description: `This sign was a long time in discussion (something like 1.5 years).
          Initially, the design involved a lot more pixels and would have required some more experimental tech,
          but I did a redesign with more recent techniques that matched the existing logo better and honestly just looked cooler.`
        },
        {
          video: "truncoct(portable).mp4",
          title: "Portable Truncated Octahedron",
          description: `After making the Truncated Octahedron I couldn't get the idea of putting the controls
          in a ball in the center along with a battery, so I did. This also features an experimental wall latching
          system that I ended up abandoning (a least for most pieces).`
        },
        {
          video: "truncoct.mp4",
          title: "Truncated Octahedron",
          description: `A smaller Archimedean solid, this is the first piece that
          cuts an extra channel out of the top and bottom covers so there's less plastic in the way and light
          can shine through better. A gift for a friend.`
        },
        {
          video: "helmetron2.mp4",
          title: "Helmetron 2",
          description: `I decided to remake Helmetron with the process from the pure 3D printed Truncated Icosidodecahedron.
          I also made it thinner and made a better box to hold the eletronics and mic for the music responsiveness.
          Fun fact, because this version has a lot more flat surfaces facing directly back you hear your voice when you speak
          and it is a little unsettling.`
        },
        {
          video: "thundercloud.mp4",
          title: "Thunder Cloud",
          description: `This piece has quite a few firsts. It's the first piece I received money for,
          the first to use black (LED black acrylic, and "smoke" transparent PETG), and the first acrylic
          piece to have a bend in it and use that whole system.`
        },
        {
          video: "truncatedicosidodecahedron.mp4",
          title: "Truncated Icosidodecahedron",
          description: `I realized how to make a Super Orbitron style shape (i.e. Archimedean solid)
          but with the more recent manufacturing style (I had been working on pieces with 3D bends to them).
          Then I realized I could 3D print the covers as well which lead to several other improvements
          (most notably snapping together without glue, and hiding the edge of the walls that otherwise peak through).`
        },
        {
          video: "pentagram.mp4",
          title: "Pentagram",
          description: `This one was made by Mana (though I helped with some of the math and the pixel info generation).
          They took a bunch of scrap LED strip I didn't intend to use and decided to make something for their ceiling.
          Initially it was just hanging there, but after we moved in August of 2024, Mana decided to encase it in transparent
          3D printed plastic (and this is unrelated to the pute 3D printed system I later devised).`
        },
        {
          video: "ravenstear.mp4",
          title: "Raven's Tear",
          description: `The Raven's Tear is a symbol I made up a couple of years ago and decided
          to adopt as the official Lumatron logo. This is the exact same topology as appears in the demo
          in the Lumatron home page.`
        },
        {
          video: "flower.mp4",
          title: "Flower",
          description: `This is the first piece to use PETG walls (which have better lighting properties)
          and have supports built into the wall to hold the LED strip. Adapated from the Mandala design.
          Gift for a friend.`
        },
        {
          video: "weather.mp4",
          title: "Weather",
          description: `This piece was a gift to my sister. It uses a pixel strip with twice as many LEDs per meter
          and the pattern incorporates data from a weather API for a location of special meaning.`
        },
        {
          video: "mirror.mp4",
          title: "Mirror",
          description: `I felt compelled to make a piece for myself that surrounded a mirror.
          Turns out it makes an excellent vanity. One of the sides of acrylic had a large section where
          the laser cutter didn't cut all the way through, and I spent over 3 hours dremeling the channels covered
          in acrylic dust; it was truly miserable.`
        },
        {
          video: "sierpinski.mp4",
          title: "Sierpinski",
          description: `This one is a larger Sierpinski's Triangle (i.e. fractal triforce to Zelda fans).
          I was thinking of selling this one for a while, but it has too many flaws and
          I decided to give to away instead.`
        },
        {
          video: "sierp.mp4",
          title: "Sierp",
          description: `I decided to switch from wood walls to acrylic. When I realized the tollerances for acrylic
          were not favorable I decided on 3D printed walls instead (and got a better 3D printer).
          This was the first test piece. Currently being used as Mana's reading lamp.`
        },
        {
          video: "helmetron.mp4",
          title: "Helmetron",
          description: `After discussions with a friend, I couldn't stop thinking about the idea of making an "Orbitron"
          you could put on your head (i.e. helmetron). Ended up added music responsiveness using a tiny 
          Arduino style chip and a low pass filter. I'm hoping to make a better version using the more up-to-date
          system reflected in the Truncated Icosidodecahedron above. Currently being used as my reading lamp.`
        },
        {
          video: "shower.mp4",
          title: "Shower",
          description: `I had a bunch of water proof LED strips I had no other use for so I whipped this together in a morning
          using the existing system.`
        },
        {
          video: "Nd.mp4",
          title: "Nd",
          description: `This is a test of a few things: black clear acrylic, the new CAT5 port for power/signal, deadend verticies,
          and shifting the LEDs so that they are no longer on the verticies. "Nd" is for neodymium for no particular reason.`
        },
        {
          video: "spaceinvader.mp4",
          title: "Space Invader",
          description: `I had been previously restricted that each "vertex" had to have an even number of "edges" in a piece.
          It occurred to me that I could solve this by making pieces two sided, and this is a prototype of that concept.
          Based on one of the sprites from Space Invaders.`
        },
        {
          video: "hexcat.mp4",
          title: "Hexcat",
          description: `This is the first flat piece that utilized generalized angles (not just 90 degress).
          Given to my parents.`
        },
        {
          video: "liz.mp4",
          title: "Organic Wall",
          description: `After making my first flat version, it occurred to me that I could laser cut the acrylic
          and fit walls in between them (still wood painted white at the time). Given to a friend.`
        },
        {
          video: "wallitron.mp4",
          title: "Wallitron",
          description: `It took me a while to decide to try what it would look like to make a flat "orbitron",
          which I called "wallitron" at the time. Made from 3D printed channels sandwiched between
          two pieces of acrylic bolted together.`
        },
        {
          video: "orbitron.mp4",
          title: "Super Orbitron",
          description: `I made a second sphere so Mana and I chould both develop on one,
          and I removed the central acrylic ball (since I never actually did anything with it).
          I ended up renaming the whole project to Super Oribtron since it had morphed to playing a series
          of minigames instead of just Bomberman. In this video you see it being demoed at 5000 Watts in 
          Sacramento and people are playing the "color war" minigame.`
        },
        {
          video: "rhomberman.mp4",
          title: "Super Rhomberman",
          description: `The very first piece. Initially conceived of to play a version of Bomberman
          (the name Super Rhomberman is play on that and the name of the shape: a rhombicosidodecahedron).`
        },
      ],
      designs: [
        {
          video: "snubdodecahedron.mp4",
          title: "Snub Dodecahedron",
          description: `Archimedean solid similar to the Truncated Icosidodecahedron above.`
        },
        {
          video: "truncatedoctahedron.mp4",
          title: "Truncated Octahedron",
          description: `Archimedean solid similar to the Truncated Icosidodecahedron above.`
        },
        {
          video: "diamonds.mp4",
          title: "Diamonds",
          description: `An alternate design for a commission that wasn't used, but I rather like.`
        },
        {
          video: "wraith.mp4",
          title: "Wraith",
          description: `A design inspired by the tree design, but ended up using all right angles.`
        },
        {
          video: "tree.mp4",
          title: "Tree",
          description: `First design to try to take advantage of a 3D bend
          (intended to fit snug against the cieling). Design is currently claimed.`
        },
        {
          video: "crane.mp4",
          title: "Crane",
          description: `Initially conceived as a pitch to make one in imitation of a company logo.`
        },
        {
          video: "hive.mp4",
          title: "Hive",
          description: `Designed as a sign for a spaced called, you guessed it, The Hive.`
        },
        {
          video: "mandala.mp4",
          title: "Mandala",
          description: `This design was later adapated in to the flower shape seen above.`
        },
      ],
    }
  },

  methods: {
    getAssetUrl(name) {
      if (!name) return
      return require("@/assets/" + name)
    },
    thumbnailNameFromVideo(name) {
      return name.replace("video", "thumbnail").replace("-loop.mp4", ".jpg")
    },

    advanceCarousel() {
      this.carouselPosition += 1
      this.updateCarousel()
    },
    updateCarousel(keepAutoadvance) {
      if (!keepAutoadvance) {
        clearInterval(window.autoAdvanceInterval)
        window.autoAdvanceInterval = null
      }
      if (this.carouselInterval) {
        return
      }
      this.carouselInterval = setInterval(() => {
        this.carouselTarget = Math.max(0, this.carouselTarget)
        this.carouselTarget = Math.min(this.videos.length - 1, this.carouselTarget)

        let motionBlur = 0
        if (!this.isDragging) {
          const alpha = 0.7
          this.carouselVelocity = alpha * this.carouselVelocity
          let startingPosition = this.carouselPosition
          this.carouselPosition += this.carouselVelocity
          this.carouselPosition = alpha * this.carouselPosition + (1 - alpha) * this.carouselTarget
          let delta = this.carouselPosition - startingPosition
          let maxSpeed = 0.4
          if (Math.abs(delta) > maxSpeed) {
            delta = maxSpeed * Math.sign(delta)
            this.carouselPosition = startingPosition + delta
          }
          motionBlur = Math.abs(delta)*2
        }
        let slides = document.querySelectorAll(".carousel video")
        for (let i = 0; i < slides.length; i++) {
          let slide = slides[i]
          let offset = i - this.carouselPosition
          slide.style.transform = `
              translateX(${Math.sign(offset)*Math.pow(Math.abs(offset), 0.5)*50}%)
              scale(${1/(Math.abs(offset)/2 + 1)})`
          slide.style.zIndex = Math.round(100 - Math.abs(offset*2))
          let opacity = 1/Math.pow(Math.abs(offset) + 1,2)
          opacity = Math.min(opacity, 1 - motionBlur)
          slide.style.opacity = opacity
        }
        
        this.$forceUpdate()
        if (Math.abs(this.carouselPosition - this.carouselTarget) < 0.0001) {
          clearInterval(this.carouselInterval)
          this.carouselInterval = null
        }
      }, 33)
    },

    handleStart(location) {
      let carouselPosition = document.querySelector(".carousel").getBoundingClientRect()
      if (carouselPosition.top > location.clientY ||
          carouselPosition.bottom < location.clientY) {
        return
      }
      this.startX = location.clientX
      this.timestamp = Date.now()
      this.isDragging = true
    },
    handleEnd() {
      if (this.isDragging) {
        let finalPosition = this.carouselPosition + this.carouselVelocity * 20
        this.carouselTarget = Math.round(finalPosition)
        this.carouselTarget = Math.max(this.carouselTarget, 0)
        this.carouselTarget = Math.min(this.carouselTarget, this.videos.length - 1)
        this.updateCarousel()
        this.isDragging = false
      }
    },
    handleChange(location) {
      if (this.isDragging) {
        let delta = (this.startX - location.clientX) / 450
        this.carouselPosition += delta
        this.carouselVelocity = Math.max(Math.min(delta,0.2),-0.2)
        this.startX = location.clientX
        this.updateCarousel()
      }
    },
  },
}
</script>

<style>

#lumatron-header {
  position: relative;
}


.catalog {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  background: var(--bg-color);
}
@media only screen and (max-device-width: 540px) {
  .catalog {
    grid-template-columns: repeat(2, 1fr);
  }
}
.piece {
  border: 8px solid var(--bg-color);
  box-sizing: border-box;
  font-size: 1rem;
  cursor: pointer;
}
.piece video {
  width: 100%;
}

#focus-piece {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}
#focus-piece .inner {
  background: black;
  width: 512px;
  max-width: 100vw;
  font-size: 1rem;
  padding: 24px 24px 48px;
  border: 4px solid #aaa;
}
#focus-piece .title {
  font-size: 2rem;
}

#focus-piece video {
  width: 100%;
}


</style>
